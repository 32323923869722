
export default {
  /**
   * 获取图片路径
   */
  getImagePath (img) {
    if (!img) return ;
    return `https://tikipay-static-resource.oss-cn-shenzhen.aliyuncs.com/tikiShop/gamePic/${img}`
    
  }
}
