import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index.vue')
  },
  {
    path: '/solution',
    name: 'solution',
    component: () => import(/* webpackChunkName: "solution" */ '@/views/solution.vue')
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: () => import(/* webpackChunkName: "marketing" */ '@/views/marketing.vue')
  },
  {
    path: '/acquiring',
    name: 'acquiring',
    component: () => import(/* webpackChunkName: "acquiring" */ '@/views/acquiring.vue')
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import(/* webpackChunkName: "payments" */ '@/views/payments.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/about.vue')
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: () => import(/* webpackChunkName: "about" */ '@/views/refresh.vue'),
    meta: {
      title: '用于同路由刷新'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.path === from.path) {
//     // 路由相同，刷新页面
//     location.reload();
//   } else {
//     // 路由不同，正常跳转
//     next();
//   }
// })

export default router
