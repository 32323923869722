const tikipay = {
  brand: "TiKiPay",
  brandLogoWhite: "../assets/image/pc/footer/foot-logo.png",
  brandLogoBlack: "../assets/image/logo-black.png",

  logoWidth: "166px",
  logoHeight: "33px",
  shopName: "TiKiShop",
};

const faspal = {
  brand: "Faspal",
  brandLogoWhite: "../assets/image/faspal-logo.png",
  brandLogoBlack: "../assets/image/faspal-logo.png",

  logoWidth: "166px",
  logoHeight: "33px",
  shopName: "FasShop",
};

const octopay = {
  brand: "OctoPay",
  brandLogoWhite: "../assets/image/octo-logo3.png",
  brandLogoBlack: "../assets/image/octo-logo3.png",

  logoWidth: "93px",
  logoHeight: "66px",
  shopName: "OctoShop",
};

let type = {}
console.log("NODE_ENV",process.env.NODE_ENV)
console.log("VUE_APP_FLAG",process.env.VUE_APP_FLAG)
if (process.env.VUE_APP_FLAG == "tikipay") { // 开发环境
  type = tikipay
} else if (process.env.VUE_APP_FLAG == "octopay") { // 测试环境 - 印尼
  type = octopay
} else if (process.env.VUE_APP_FLAG == "faspal") { // 测试环境 - 印尼
  type = faspal
}  else {
  type = tikipay
}
export default type
