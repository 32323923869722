import VueI18n from "vue-i18n"
import Vue from "vue"

Vue.use(VueI18n)

export default new VueI18n({
  locale: localStorage.getItem('lang') || "en-US", //语言标识
  messages: {
    "en-US": require("./locales/en-US.json"), // 英文
    "zh-CN": require("./locales/zh-CN.json"), // 中文
  },
  fallbackLocale: "en-US",
})
