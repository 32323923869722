<template>
  <div :class="isMobile ? 'mobile footer' : 'pc footer' ">
    <div class="footer-part1" v-if="!isMobile">
      <div class="left">
        <img class="foot-logo" :style="logoStyle" src="../assets/image/logo-white.png" alt="" v-if="brand == 'TiKiPay'">
        <img class="foot-logo" :style="logoStyle" src="../assets/image/octo-logo3.png" alt="" v-if="brand == 'OctoPay'">
        <img class="foot-logo" :style="logoStyle" src="../assets/image/faspal-logo.png" alt="" v-if="brand == 'Faspal'">
        <h4 class="foot-title">{{ $t('foot1', {brand: this.brand}) }}</h4>
        <a @click="jumpById('about', 'contact')" class="btn-register">{{ $t('foot2') }}</a>
      </div>
      <div class="right">
        <div class="server-foot foot-right">
          <h4>{{ $t('foot3') }}</h4>
          <ul class="foot-list">
            <li>
              <a @click="jump('acquiring')" href="javascript:;">{{ $t('foot4') }}</a>
            </li>
            <li>
              <a @click="jump('payments')" href="javascript:;">{{ $t('foot5') }}</a>
            </li>
            <li>
              <a @click="jump('marketing')" href="javascript:;">{{ $t('foot6') }}</a>
            </li>
          </ul>
        </div>
        <div class="about-foot foot-right">
          <h4>{{ $t('foot7') }}</h4>
          <ul class="foot-list">
            <li>
              <a @click="jump('about')" href="javascript:;">{{ $t('foot8') }}</a>
            </li>
            <li>
              <a @click="jumpById('about', 'contact')" href="javascript:;">{{ $t('foot9') }}</a>
            </li>
          </ul>
        </div>
        <div class="contact-foot foot-right">
          <h4>{{ $t('foot9') }}</h4>
          <ul class="contact-list">
            <li>
              <div class="wechat-wrapper">
                <i class="icon icon-wechat" />
                <div class="wechat-box">
                  <img class="wx-img" src="../assets/image/pc/footer/wx.jpg" alt="">
                </div>
              </div>
            </li>
            <li>
              <a :href="facebookHref">
                <i class="icon icon-facebook" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-part1" v-if="isMobile">
      <div class="footer-content1">
        <div class="left">
<!--          <img class="foot-logo" src="../assets/image/pc/footer/foot-logo.png" alt="">-->
          <img class="foot-logo" :style="logoStyle" src="../assets/image/pc/footer/foot-logo.png" alt="" v-if="brand == 'TiKiPay'">
          <img class="foot-logo" :style="logoStyle" src="../assets/image/octo-logo3.png" alt="" v-if="brand == 'OctoPay'">
          <img class="foot-logo" :style="logoStyle" src="../assets/image/faspal-logo.png" alt="" v-if="brand == 'Faspal'">
          <h4 class="foot-title">{{ $t('foot1', {brand: this.brand}) }}</h4>
          <a @click="jumpById('about', 'contact')" class="btn-register">{{ $t('foot2') }}</a>
        </div>
      </div>
      <div class="footer-content2">
        <div class="right">
          <div class="server-foot foot-right">
            <h4>{{ $t('foot3') }}</h4>
            <ul class="foot-list">
              <li>
                <a @click="jump('acquiring')" href="javascript:;">{{ $t('foot4') }}</a>
              </li>
              <li>
                <a @click="jump('payments')" href="javascript:;">{{ $t('foot5') }}</a>
              </li>
              <li>
                <a @click="jump('marketing')" href="javascript:;">{{ $t('foot6') }}</a>
              </li>
            </ul>
          </div>
          <div class="about-foot foot-right">
            <h4>{{ $t('foot7') }}</h4>
            <ul class="foot-list">
              <li>
                <a @click="jump('about')" href="javascript:;">{{ $t('foot8') }}</a>
              </li>
              <li>
                <a @click="jumpById('about', 'contact')" href="javascript:;">{{ $t('foot9') }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="contact-foot foot-right">
            <h4>{{ $t('foot9') }}</h4>
            <ul class="contact-list">
              <li>
                <div class="wechat-wrapper">
                  <i class="icon icon-wechat" @click="isShow = !isShow"/>
                  <div :class="isShow ? 'wechat-box active': 'wechat-box'">
                    <img class="wx-img" src="../assets/image/pc/footer/wx.jpg" alt="">
                  </div>
                </div>
              </li>
              <li>
                <a :href="facebookHref">
                  <i class="icon icon-facebook" />
                </a>
              </li>
            </ul>
        </div>
        <p class="footer-part2">
          Copyright © 2024 {{ brand }} Rights Reserved.
        </p>
      </div>
    </div>
    <p class="footer-part2" v-if="!isMobile">
      Copyright © 2024 {{ brand }} Rights Reserved.
    </p>
  </div>
</template>

<script>
import env from "../assets/js/env";
export default {
  data() {
    return {
      isMobile: false,
      brandLogo: env.brandLogoWhite,
      logoWidth: env.logoWidth,
      logoHeight: env.logoHeight,
      brand: env.brand,
      facebookHref: '',
      isShow: false,
    }
  },
  computed: {
    logoStyle() {
      return {
        "width": this.logoWidth,
        "height": this.logoHeight,
      }
    }
  },
  mounted() {
    if (this._isMobile()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    if (this.brand === 'TiKiPay') {
      this.facebookHref = 'https://www.facebook.com/profile.php?id=100095448819973&mibextid=LQQJ4d'
    } else {
      this.facebookHref = '#'
    }
  },
  watch: {
    "$route.path": {
      immediate: true,
      deep: true,
      handler() {
        const id = this.$route.query.id;
        if (id) {
          this.scrollTarget(id)
        } else {
          window.scrollTo({
            top: 0,
            behavior: 'instant',
          });
        }
      },
    },
  },
  methods: {
    scrollTarget(id) {
      const targetElement = document.getElementById(id);
      if (targetElement) {
        setTimeout(() => {
          window.scrollTo({
            top: targetElement.offsetTop,
            behavior: 'instant',
          });
        }, 100);
      }
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    jump(path) {
      if (this.$route.path === `/${path}`) {
        this.$router.replace('/refresh')
        setTimeout(() => {
          this.$router.push({
            path: `/${path}`
          })
        }, 50);
      } else {
        this.$router.push({
          path: `/${path}`
        })
      }
    },
    jumpById(path, id) {
      if (this.$route.path === `/${path}`) {
        this.$router.replace('/refresh')
        this.$router.push({
          path: `/${path}`,
          query: {
            id: id
          }
        })
      } else {
        this.$router.push({
          path: `/${path}`,
          query: {
            id: id
          }
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
a:hover {
  text-decoration: none;
  opacity: 0.8;
}
img {
  width: 100%;
  height: 100%;
}
p {
  margin-bottom: 0;
}
.footer {
  max-width: 1180px;
  margin: 0 auto;
}
.footer-part1 {
  display: flex;
  text-align: left;
  padding-bottom: 89px;
  .left {
    width: 400px;
  }
  .right {
    display: flex;
  }
  .foot-logo {
    width: 166px;
    height: 33px;
    margin-bottom: 25px;
  }
  .foot-title {
    font-size: 36px;
    
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 47px;
  }
  .btn-register {
    padding: 0 29px;
    height: 50px;
    background: #236EF6;
    transition: all .3s ease;
    border: 1px solid #236EF6;
    border-radius: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    
    font-weight: 400;
    color: #FFFFFF;
    &:hover {
      background: 0 0;
      color: #236EF6;
      opacity: 1;
    }
  }
  .foot-right {
    margin-left: 100px;
    min-width: 184px;
    h4 {
      font-size: 20px;
      
      font-weight: 500;
      color: #FFFFFF;
      line-height: 25px;
      margin-bottom: 30px;
    }
    .foot-list {
      li {
        margin-bottom: 33px;
        font-size: 18px;
        
        font-weight: 400;
        line-height: 25px;
        a {
          color: #CBCBCB;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .contact-list {
      display: flex;
      li {
        margin-left: 21px;
        .icon {
          transition: all .3s ease;
          width: 44px;
          height: 44px;
          display: block;
          &.icon-facebook {
            background-image: url('../assets/image/pc/footer/icon-facebook.png');
          }
          &.icon-wechat {
            background-image: url('../assets/image/pc/footer/icon-wx.png');
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          
          .icon {
            &.icon-facebook {
              background-image: url('../assets/image/pc/footer/icon-facebook-hover.png');
            }
            &.icon-wechat {
              background-image: url('../assets/image/pc/footer/icon-wx-hover.png');
            }
          }
          .wechat-box {
            display: block;
          }
        }
      }

      .wechat-wrapper {
        position: relative;
        .wechat-box {
          position: absolute;
          background: #fff;
          padding: 8px;
          display: none;
          background: #FFFFFF;
          border-radius: 8px;
          top: 65px;
          left: 50%;
          transform: translateX(-50%);
          .wx-img {
            width: 100px;
            height: 100px;
            display: block;
          }
          &:after {
            content: "";
            position: absolute;
            top: -16px;
            left: 50%;
            margin-left: -8px;
            border-width: 8px;
            border-style: solid;
            border-color: #fff transparent transparent;
            transform: rotate(180deg);
          }
        }
      }
    }
    &.contact-foot {
      min-width: auto;
    }
  }

}
.footer-part2 {
  padding-top: 43px;
  font-size: 15px;
  
  font-weight: 600;
  color: #C7C7C7;
  line-height: 20px;
  border-top: 1px solid rgba(138, 144, 160, 0.3);
}
.mobile {
  .footer-part1 {
    display: block;
    padding-bottom: 0;
    .footer-part2 {
      text-align: center;
      font-size: px2rem(20);
      padding-top: px2rem(42);
    }
    .foot-right {
      margin-left: 0;
      min-width: auto;
      flex: 1;
      h4 {
        font-size: px2rem(28);
        line-height: px2rem(32);
        margin-bottom: px2rem(42);
      }
      .foot-list {
        li {
          font-size: px2rem(26);
          line-height: px2rem(30);
          margin-bottom: px2rem(36);
          a {
            color: #CBCBCB;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .contact-list {
        display: flex;
        margin-bottom: px2rem(73);
        li {
          margin-left: px2rem(21);
          .icon {
            transition: all .3s ease;
            width: px2rem(58);
            height: px2rem(58);
            background-size: px2rem(58) px2rem(58);
            display: block;
            &.icon-facebook {
              background-image: url('../assets/image/pc/footer/icon-facebook.png');
            }
            &.icon-wechat {
              background-image: url('../assets/image/pc/footer/icon-wx.png');
            }
          }
          &:first-child {
            margin-left: 0;
          }
          .wechat-box.active {
            display: block;
          }
        }

        .wechat-wrapper {
          position: relative;
          .wechat-box {
            position: absolute;
            background: #fff;
            padding: px2rem(8);
            display: none;
            background: #FFFFFF;
            border-radius: px2rem(8);
            top: 50%;
            left: px2rem(86);
            transform: translateY(-50%);
            .wx-img {
              width: px2rem(218);
              height: px2rem(218);
              display: block;
            }
            &:after {
              content: "";
              position: absolute;
              top: 50%;
              left: px2rem(-10);
              margin-top: px2rem(-14);
              border-width: px2rem(14);
              border-style: solid;
              border-color: #fff transparent transparent;
              transform: rotate(90deg);
            }
          }
        }
      }
      &.contact-foot {
        margin-top: px2rem(75);
      }
    }
    .left {
      background: #08254D;
      border-radius: px2rem(20);
      width: 100%;
      text-align: center;
      padding: px2rem(70) px2rem(24) px2rem(63);
    }
    .footer-content1 {
      padding: 0 px2rem(32);
    }
    .foot-logo {
      height: px2rem(41);
      width: auto;
      margin-bottom: px2rem(40);
    }
    .foot-title {
      font-size:  px2rem(46);
      line-height: 1.2;
      margin-bottom: px2rem(49);
    }
    .btn-register {
      padding: 0 px2rem(50);
      height: px2rem(55);
      background: #236EF6;
      border: 1px solid #236EF6;
      border-radius: px2rem(28);
      font-size: px2rem(22);
      &:hover {
        color: #fff;
      }
    }
    .footer-content2 {
      background: #08254D;
      padding:  px2rem(85) px2rem(32) px2rem(73);
      margin-top: px2rem(65);
    }
  }
}

</style>

<style lang="scss">
.EN {
  .footer-part1 .foot-title {
    font-size: 22px;
  }
  .footer-part1 .foot-right .foot-list li {
    font-size: 16px;
  }
}
</style>