<template>
  <div id="app" :class="[isMobile ? 'mobile':'pc', country]">
    <div
      :class="isMobile ? 'components-layout mobile' : 'components-layout'"
      ref="layout"
    >
      <a-layout>
        <a-layout-header :class="isTop && hoverVal === '' && (!showFlag || !isMobile ) ? '': 'head-fix'">
          <headerVue @onHandlerShow="isShow" @onHoverValue="hoverValue" :isTop="isTop" />
        </a-layout-header>
        <a-layout-content>
          <router-view />
        </a-layout-content>
        <a-layout-footer>
          <footerVue />
        </a-layout-footer>
      </a-layout>
    </div>
  </div>
</template>

<script>
import headerVue from './views/header.vue';
import footerVue from './views/footer.vue';
export default {
  components: { footerVue, headerVue },
  computed: {
    isGameDetailPage() {
      return this.$route.path.includes('gameDetail');
    }
  },
  data() {
    return {
      isMobile: false,
      isTop: true,
      hoverVal: '',
      showFlag: false,
      country: 'EN',
      lang: [
        { key: "en-US", label: "EN" },
        { key: "zh-CN", label: "CN" },
      ],
    }
  },
  watch: {
    "$global.lang": {
      immediate: true,
      deep: true,
      handler() {
        const currentLang = localStorage.getItem('lang')
        this.lang.forEach(element => {
          if (currentLang.indexOf(element.key) !== -1) {
            this.country = element.label
          }
        });
      },
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    
    window.addEventListener('scroll', this.scrollToTop)
  },
  methods: {
    hoverValue(hoverValue) {
      console.log(hoverValue, 'hoverValue')
      // childValue就是子组件传过来的值
      this.hoverVal = hoverValue
    },
    isShow(val) {
      console.log('dianle', val)
      // childValue就是子组件传过来的值
      this.showFlag = val
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    scrollToTop() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(scrollTop)
      if (scrollTop !== 0) {
        this.isTop = false;
      } else {
        this.isTop = true;
      }
    }
  }
}
</script>

<style lang="scss">
@import './assets/css/reset.scss';
html.mobile {
  font-size: 13.3333vw;
  background: #1a1a1a;
}
#app {
  font-family: "Open Sans",PingFangSC-Regular,Verdana,"Hiragino Sans","Microsoft YaHei",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  &.pc {
    min-width: 1200px;
    overflow-x: auto;
  }
}
</style>

<style lang="scss" scoped>
.components-layout {
  .ant-layout-header {
    height: 98px;
    background: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    min-width: 1180px;
  }
  .head-fix {
    position: fixed;
    background: #fff;
    transition: all .3s ease;
    box-shadow: 0 0 10px 2px rgba(0,0,0, 0.1);
  }
  .ant-layout-footer {
    background: #0F264A;
    color: #fff;
    padding: 60px 0;
  }
  .ant-layout-content {
    color: #fff;
    min-height: calc(100vh - 68px);
  }
}

.mobile {
  .components-layout {
    .ant-layout-header {
      height: auto;
      padding: 0;
      min-width: auto;
    }
    .ant-layout-footer {
      background: #fff;
      color: #fff;
      padding: px2rem(61) 0 0;
    }
    .ant-layout-content {
      background: #111111;
      color: #fff;
      min-height: calc(100vh - 68px);
    }
  }
}
</style>
