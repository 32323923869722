import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "./i18n.js"
import Antd from 'ant-design-vue';
import '@/assets/js/http'
import 'ant-design-vue/dist/antd.css'
import store from './store';
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.use(Antd);

const globalData = Vue.observable({
  lang: 'en', // 默认语言为英文
});

Vue.prototype.$global = globalData; // 将可响应对象存储在全局变量中
Vue.config.productionTip = false

if (localStorage.lang !== undefined) {
  i18n.locale =  localStorage.getItem('lang')
} else {
  localStorage.setItem("lang", "en-US")
  i18n.locale = localStorage.getItem('lang')
}

new Vue({
  router,
  i18n,
  store,
  mounted() {
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app')
